import { React, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { getrequest } from "../../services/global.service";
import {
  CalendarPicker,
  InputNumber,
  Select,
  Tooltip,
  TextArea,
  Loading,
  Button,
  Tabs,
  Tab,
  Panel,
} from "@appkit4/react-components";

const SurveyForm = () => {
  const [questinnaireJSON, setquestinnaireJSON] = useState([]);
  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [surveyName, setSurveyName] = useState(false);
  const [activeIndex, setactiveIndex] = useState(0);

  let location = useLocation();
  const navigate = useNavigate();
  const { surveyId } = useParams();

  Cookies.set("path", location.pathname, {
    sameSite: "strict",
    secure: true,
  });
  var getSurveyDetailsURL = `${process.env.REACT_APP_API_BASE_URL}/api/Survey/GetSurveyDetail`;

  useEffect(() => {
    setIsLoading(true);
    getSurveyDetails();
  }, []);

  const onTabChange = (i) => {
    setactiveIndex(i);
  };

  const allTooltip = (text, customClass) =>
    (text !== "" || text === undefined) && (
      <Tooltip
        trigger="hover"
        position="top-left"
        distance={4}
        appendAfterTarget={true}
        content={text}
      >
        <button
          data-tooltip="true"
          tabIndex={0}
          aria-label="tooltip"
          className={`Appkit4-icon icon-information-outline ap-field-icon-btn mb-md-3 ${customClass}`}
          aria-describedby="field-tooltip"
        ></button>
      </Tooltip>
    );

  const handleChange = (value, e) => {
    setData({
      ...data,
      [e.target.name]: value,
    });
  };

  const handleNumberChange = (value, _formattedValue, e) => {
    setData({
      ...data,
      [e.target.name]: value
        .toString()
        .split(".")
        .map((el, i) => (i ? el.split("").slice(0, 2).join("") : el))
        .join("."),
    });
  };

  if (isLoading)
    return (
      <Loading
        loadingType="linear"
        indeterminate={true}
        compact={false}
        className="page-loader"
      ></Loading>
    );

  const buildTable = (colums, data) => {
    return (
      <table className="survey-table">
        <tr>
          <th></th>
          {data.map((d) => (
            <th>{d.components[0].label}</th>
          ))}
        </tr>
        {colums?.map((c) => (
          <tr>
            <td>{c}</td>
            {data.map((d) => (
              <td>{getFields(d.components[0], true, c)}</td>
            ))}
          </tr>
        ))}
      </table>
    );
  };

  const getFields = (question, table, c) => {
    if (
      !question.customConditional ||
      eval(
        question.customConditional
          ?.substring(question.customConditional?.indexOf("=") + 1)
          .trim()
          ?.replace(/\.includes/g, "?.includes")
          ?.replace(/\)data/g, ") && data")
      )
    ) {
      if (question.type === "textfield") {
        return (
          <>
            <TextArea
              title={!table && question.label}
              defaultValue={question.defaultValue}
              required={question.validate.required}
              maxLength={question.attributes.maxlength}
              name={
                table
                  ? `${question.key}_${c?.replace(/\s/g, "")}`
                  : question.key
              }
              onChange={handleChange}
              value={
                data[
                  table
                    ? `${question.key}_${c?.replace(/\s/g, "")}`
                    : question.key
                ]
              }
              className={
                question.tooltip === "" || !question.tooltip
                  ? `mb-md-3 ${question.customClass}`
                  : question.customClass
              }
            />
            {question.tooltip &&
              allTooltip(question.tooltip, question.customClass)}
          </>
        );
      } else if (question.type === "select") {
        return (
          <>
            <Select
              data={question.data.values}
              required={question.validate.required}
              showSelectAll={true}
              multiple={question.multiple}
              placeholder={!table && question.label}
              onSelect={(value) =>
                setData((prevState) => ({
                  ...prevState,
                  [table
                    ? `${question.key}_${c?.replace(/\s/g, "")}`
                    : question.key]: value,
                }))
              }
              value={
                data[
                  table
                    ? `${question.key}_${c?.replace(/\s/g, "")}`
                    : question.key
                ]
              }
              className={
                question.tooltip === "" || !question.tooltip
                  ? `mb-md-3 ${question.customClass}`
                  : question.customClass
              }
            />
            {question.tooltip &&
              allTooltip(question.tooltip, question.customClass)}
          </>
        );
      } else if (question.type === "datetime") {
        return (
          <>
            <CalendarPicker
              data={question.values}
              fieldTitle={!table && question.label}
              defaultValue={question.defaultValue}
              format={"DD/MM/YYYY"}
              editable={false}
              fieldWidth="100%"
              placeholder="DD/MM/YYYY"
              required={question.validate.required}
              disabledDays={[].concat(
                question.datePicker.disableWeekends && [5, 6],
                question.datePicker.disableWeekdays && [0, 1, 2, 3, 4]
              )}
              onChange={(vals) =>
                setData((prevState) => ({
                  ...prevState,
                  [table
                    ? `${question.key}_${c?.replace(/\s/g, "")}`
                    : question.key]: vals,
                }))
              }
              value={
                data[
                  table
                    ? `${question.key}_${c?.replace(/\s/g, "")}`
                    : question.key
                ]
              }
              className={
                question.tooltip === "" || !question.tooltip
                  ? `mb-md-3 ${question.customClass}`
                  : question.customClass
              }
            />
            {question.tooltip &&
              allTooltip(question.tooltip, question.customClass)}
          </>
        );
      } else if (question.type === "number") {
        return (
          <>
            <InputNumber
              title={!table && question.label}
              defaultValue={question.defaultValue}
              required={question.validate.required}
              name={
                table
                  ? `${question.key}_${c?.replace(/\s/g, "")}`
                  : question.key
              }
              onChange={handleNumberChange}
              value={
                data[
                  table
                    ? `${question.key}_${c?.replace(/\s/g, "")}`
                    : question.key
                ]
              }
              className={
                question.tooltip === "" || !question.tooltip
                  ? `mb-md-3 ${question.customClass}`
                  : question.customClass
              }
              onInput={(e) =>
                (e.target.value = e.target.value.slice(
                  0,
                  question.attributes.maxlength
                ))
              }
              increaseIconClassName="inputNumberArrow"
              decreaseIconClassName="inputNumberArrow"
            />
            {question.tooltip &&
              allTooltip(question.tooltip, question.customClass)}
          </>
        );
      } else if (question.type === "memo") {
        return <p>{question.label?.replace(/^[0-9]+/g, "")}</p>;
      } else if (question.type === "datagrid") {
        return (
          <>
            <p>
              {question.label}

              {question.tooltip !== "" && (
                <Tooltip
                  trigger="hover"
                  position="top-left"
                  distance={4}
                  appendAfterTarget={true}
                  content={question.tooltip}
                >
                  <button
                    data-tooltip="true"
                    tabIndex={0}
                    aria-label="tooltip"
                    className="Appkit4-icon icon-information-outline ap-field-icon-btn"
                    aria-describedby="field-tooltip"
                  ></button>
                </Tooltip>
              )}
            </p>
            {buildTable(
              question.defaultValue[0],
              question.components[0].rows[0]
            )}
          </>
        );
      } else {
        return <p>There is a problem displaying this question</p>;
      }
    }
  };

  const getSurveyDetails = async () => {
    await getrequest(getSurveyDetailsURL, {
      params: {
        surveyId: surveyId,
        teamName: JSON.parse(Cookies.get("seletedTeam")).name,
      },
    })
      .then((response) => {
        if (response.data) {
          let data = response.data.data;
          let arr = JSON.parse(data);

          setSurveyName(arr[0].surveyName);

          setquestinnaireJSON(
            JSON.parse(arr[0].surveyQuestionnaire).components[0].components
          );

          setIsLoading(false);
        } else {
          navigate("/unauthorised");
        }
      })
      .catch((error) => {
        navigate("/service-problem");
      });
  };

  return (
    <div className="ap-container">
      <Button
        onClick={() => {
          navigate("/surveylist");
        }}
        kind="text"
        className="back-button"
      >
        Back
      </Button>

      <Panel title={surveyName}>
        <Tabs
          type="underline"
          activeIndex={activeIndex}
          onTabChange={onTabChange}
          responsive
        >
          {questinnaireJSON.map((component) => (
            <Tab label={component.label} value={component.value}>
              {component.components.map((question) => getFields(question))}
            </Tab>
          ))}
        </Tabs>

        <div className="row">
          <div className="col-1">
            <Button
              kind="text"
              icon="icon-left-chevron-outline"
              onClick={() => setactiveIndex(activeIndex - 1)}
              disabled={activeIndex === 0}
            >
              Previous
            </Button>
          </div>
          <div className="col-5">
            <Button
              kind="text"
              icon="icon-right-chevron-outline"
              onClick={() => setactiveIndex(activeIndex + 1)}
              disabled={activeIndex === questinnaireJSON.length - 1}
            >
              Next
            </Button>
          </div>
        </div>
      </Panel>
    </div>
  );
}

export default SurveyForm;
